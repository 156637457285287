// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Control from "../../../../../styleguide/components/Control/Control.res.js";
import * as Spinner from "../../../../../styleguide/components/Spinner/Spinner.res.js";
import * as Dropdown from "../../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as Container from "../../../../../styleguide/components/Container/Container.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CitiesDashboardScss from "../CitiesDashboard.scss";

var css = CitiesDashboardScss;

function CitiesDashboardActionBar(props) {
  var deselect = props.deselect;
  var selection = props.selection;
  var x = selection.length;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs(Container.make, {
                    className: css.actionBarInner,
                    children: [
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("span", {
                                    children: x !== 1 ? String(x) + " cities selected" : "1 city selected"
                                  }),
                              JsxRuntime.jsx(Control.AsLink.make, {
                                    color: "White",
                                    onClick: (function (param) {
                                        deselect();
                                      }),
                                    children: "Cancel"
                                  })
                            ],
                            className: css.actionBarInfo
                          }),
                      JsxRuntime.jsx("div", {
                            children: props.error ? "Something went wrong" : null,
                            className: css.actionBarError
                          }),
                      props.busy ? JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Spinner.make, {
                                    size: "LG",
                                    color: "White"
                                  }),
                              className: css.actionBarSpinner
                            }) : JsxRuntime.jsxs(Dropdown.make, {
                              children: [
                                JsxRuntime.jsx(Dropdown.Trigger.make, {
                                      className: css.actionBarDropdown,
                                      children: "Bulk action"
                                    }),
                                JsxRuntime.jsxs(Dropdown.Body.make, {
                                      position: {
                                        TAG: "Above",
                                        _0: "RightEdge"
                                      },
                                      className: css.dropdownBody,
                                      children: [
                                        Belt_Array.some(selection, (function (x) {
                                                if (x) {
                                                  return false;
                                                } else {
                                                  return true;
                                                }
                                              })) ? JsxRuntime.jsx(Control.make, {
                                                className: css.dropdownBodyRow,
                                                onClick: props.activate,
                                                children: "Activate"
                                              }) : null,
                                        Belt_Array.some(selection, (function (x) {
                                                if (x) {
                                                  return true;
                                                } else {
                                                  return false;
                                                }
                                              })) ? JsxRuntime.jsx(Control.make, {
                                                className: css.dropdownBodyRow,
                                                onClick: props.deactivate,
                                                children: "Deactivate"
                                              }) : null
                                      ]
                                    })
                              ]
                            })
                    ]
                  }),
              className: Cx.cx([
                    css.actionBar,
                    selection.length !== 0 ? css.shown : css.hidden
                  ])
            });
}

var make = CitiesDashboardActionBar;

export {
  css ,
  make ,
}
/* css Not a pure module */
